/* NOTE: no need to add '@tailwind base' here because it is already handled by CSS import from @brenger/react */
@tailwind components;
@tailwind utilities;

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-with-links a {
  text-decoration: underline;
}
