.wp-content {
  .wp-block-post-title {
    margin: 0 0 1rem 0;
  }
  p {
    margin: 0 0 0.75rem 0;
  }
  a {
    text-decoration: underline;
  }
}
