/**
 * Modal
 */
body:has(#root-modal .brenger-modal) {
  overscroll-behavior: contain !important;
  overflow: hidden !important;
}

#root-modal {
  overscroll-behavior: contain;
}

.brenger-backdrop {
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.brenger-modal {
  transform: translate3d(0, 20vh, 0);
  animation: rollToTop 0.3s 0.1s forwards;
}

@keyframes rollToTop {
  0% {
    transform: translate3d(0, 20vh, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
/**
* End
*/
