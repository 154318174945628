@import "../assets/common/tools";

.bar-animation {
  animation: loaderBar 1s linear infinite;
}

@keyframes loaderBar {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(400%, 0, 0);
  }
}

.van {
  left: 50%;
  margin-left: -76px;
  transform-origin: bottom left;
  animation: vanAnimate 1s 0.8s ease-in 1 forwards;
  @include sm-up {
    animation-duration: 1.5s;
  }
  @include lg-up {
    animation-duration: 2s;
  }
}

@keyframes vanAnimate {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  15% {
    transform: translate3d(-5vw, 0, 0) rotate(0deg);
  }
  80% {
    transform: translate3d(-5vw, 0, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(75vw, 0, 0) rotate(0deg);
  }
}
