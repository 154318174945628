.show-when-fc-hidden {
  display: none;
  .fc-hidden & {
    display: block;
  }
}

.show-when-fc-shown {
  display: block;
  .fc-hidden & {
    display: none;
  }
}

body.fc-hidden {
  #fc_frame {
    display: none;
  }
}
